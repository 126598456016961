/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';

import {
  darken,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatDateBr } from '../../../utils/formatDate';
import Modal from '../../Modal';

import api from '../../../services/api';

import LoadingButton from '@mui/lab/LoadingButton';
import { useToast } from '../../../hooks/toast';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  ordensData: any[];
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: string) => string;
}

type CustomErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

const columns: Column[] = [
  {
    id: 'createdat',
    label: 'Data',
    minWidth: 170,
    align: 'center',
    format: (value: string) => formatDateBr(value),
  },
  { id: 'agendamentoid', label: 'Agendamento', minWidth: 100, align: 'center' },
  {
    id: 'carregamentoid',
    label: 'Carregamento',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'pedido',
    label: 'Pedido',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'pedido2',
    label: 'Pedido2',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'volume2',
    label: 'Volume2',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'action',
    label: '',
    minWidth: 140,
    align: 'center',
  },
];

const ModalOrdemContingencia = React.forwardRef<HTMLInputElement, IModalProps>(
  ({ isOpen, setIsOpen, ordensData }) => {
    const { addToast } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleSaveOrdemContingenciaToSap = async (agendamentoId: string) => {
      setIsLoading(true);

      await api
        .post('/ordenscontingencia', {
          agendamentoid: agendamentoId,
        })
        .then(() => {
          console.log(
            'Ordem de contingencia enviada com sucesso para o SAP, agendamentoId:',
            agendamentoId,
          );

          // Show Toast for success
          addToast({
            type: 'success',
            title: 'Sucesso.',
            description: `Exclusão realizada com sucesso!`,
          });

          setIsLoading(false);
        })
        .catch((error: any) => {

          console.log(
            'Erro ao enviar ordem de contingencia para o SAP, agendamentoId:',
            agendamentoId,
            error,
          );

          let customError: CustomErrorType | null = null;
          if (!error.response?.data.message) {
            customError = {
              response: {
                data: {
                  message: `Erro ao enviar ordem de contingencia para o SAP! Agnid: ${agendamentoId}`,
                },
              },
            };
          }

          // Show Toast for failure
          addToast({
            type: 'error',
            title: 'Erro ao enviar ordem de contingencia para o SAP.',
            description:
              customError !== null
                ? `${customError.response.data.message}`
                : `${error.response.data.message}`,
          });

          setIsLoading(false);
        });
    };

    return (

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="1200px">
        <h1
          style={{
            color: '#00a859',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Ordem Contigencia
        </h1>
        <h6
          style={{
            textAlign: 'center',
            marginBottom: '8px',
            opacity: 0.7,
          }}
        >
          Lista de ordens que não foram integradas no SAP.
        </h6>
        <h6
          style={{
            textAlign: 'center',
            marginBottom: '8px',
            opacity: 0.7,
          }}
        >
          Para evitar duplicidades. Favor, verificar se a ordem realmente não
          existe no SAP antes de enviar.
        </h6>

        <h6
          style={{
            textAlign: 'center',
            marginBottom: '1.5rem',
            opacity: 0.7,
          }}
        >
          Clique no botão de um agendamento da tabela abaixo para iniciar o
          processo de envio da ordem para o SAP.
        </h6>

        <Paper sx={{ width: '100%',  height: '440px', border: 'none', boxShadow: 'none' }}>
          <TableContainer sx={{  height: '440px', padding: 2, border: 'none', boxShadow: 'none' }}>
            <Table
              sx={{ minWidth: 450 }}
              size="small"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead sx={{ padding: 1 }}>
                <TableRow sx={{ padding: 1 }}>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 0, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ordensData.length > 0 ? (
                  ordensData.sort((a, b) => (b.agendamentoid > a.agendamentoid ? 1 : -1)).map((ordemContingencia, index) => {
                    return (
                      <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                        {columns.map(column => {
                          const value = ordemContingencia[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'action' ? (
                                <LoadingButton
                                  loading={isLoading}
                                  loadingPosition="center"
                                  variant="contained"
                                  onClick={() =>
                                    handleSaveOrdemContingenciaToSap(
                                      ordemContingencia.agendamentoid,
                                    )
                                  }
                                  sx={{
                                    background: '#00a859',
                                    height: '2.5rem',
                                    borderRadius: '0.5rem',
                                    width: '5rem',
                                    ':hover': {
                                      background: darken('#00a859', 0.3),
                                    },
                                  }}
                                >
                                  <span>Enviar</span>
                                </LoadingButton>
                              ) : column.format ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Nenhuma ordem de contingencia encontrada.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>


    );
  },
);

ModalOrdemContingencia.displayName = 'ModalOrdemContingencia';

export default ModalOrdemContingencia;
