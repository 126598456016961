import React, { ReactElement, useEffect, useState } from 'react';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'react-day-picker/dist/style.css';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { Container, TableContainer } from './styles';
import api from '../../services/api';
// import { formatDate } from '../../utils/formatDate';
import { Box, CircularProgress } from '@mui/material';

type SaldoProps = {
  pedido: number;
  date: string;
  orderType: string;
  material: number;
  unidadeControle: string;
  quantidade: number;
  quantidadeFaturada: number;
  saldoOrder: number;
  volumeAgendado: number;
  saldoCalculado: number;
  status: string;
  cliente: {
    businessPartner: string;
    cnpj: string;
    name: string;
    uf: string;
  };
  refPedidoSca: string;
};

function PainelSaldos(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [saldos, setSaldos] = useState<SaldoProps[]>([]);

  // Load Agendamentos Information
  useEffect(() => {
    async function loadSaldoOrdens(): Promise<void> {
      setLoading(true);
      await api
        .get(`/agendamentos/saldoAllPedidos`)
        .then(response => {
          console.log('response', response.data.length);
          setSaldos(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.log('Error loading saldo ordens', error);
        });
    }
    loadSaldoOrdens();
  }, []);

  // Generate pdf report
  function handleReportGenerate() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    // eslint-disable-next-line func-names
    const reportHeader = function (data) {
      doc.setFontSize(18);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor(0, 168, 90);
      doc.text(
        `Bom Sucesso Agroindústria - Saldo ordens em aberto`,
        data.settings.margin.left,
        15,
      );
    };

    // define an empty array of rows
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableRows: any = [];

    // for each registry pass all its data into an array

    saldos.forEach(saldo => {
      const saldosData = [
        saldo.pedido,
        moment(saldo.date).format('DD/MM/YYYY'),
        saldo.cliente.businessPartner,
        saldo.cliente.name,
        saldo.orderType,
        saldo.material,
        saldo.unidadeControle,
        saldo.quantidade,
        saldo.quantidadeFaturada,
        saldo.saldoOrder,
        saldo.volumeAgendado,
        saldo.saldoCalculado,
      ];
      // push each record info into a row
      tableRows.push(saldosData);
    });

    // generate the table based in the table below
    autoTable(doc, {
      html: '#tableDataPesagens',
      startY: 20,
      margin: 20,
      showFoot: 'lastPage',
      showHead: 'everyPage',
      rowPageBreak: 'avoid',
      didDrawPage: reportHeader,
      headStyles: {
        fillColor: [0, 168, 90],
        valign: 'middle',
        halign: 'center',
      },
      footStyles: {
        fillColor: [0, 168, 90],
      },
      bodyStyles: {
        fontSize: 8,
        valign: 'middle',
        halign: 'center',
      },
    });

    const date = Date().split(' ');
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    // name of file to be saved
    doc.save(`RelatorioCarregamentos${dateStr}.pdf`);
  }

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/painellogistica')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>

      <h2 id="pageTitle">Saldo Ordens</h2>

      {loading && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="10rem"
        >
          <CircularProgress sx={{ color: '#00a859' }} />
        </Box>
      )}

      {/* Table Agendamentos */}
      {!loading && saldos.length > 0 ? (
        <div id="buttonReportHolder">
          <button
            type="submit"
            style={{
              position: 'absolute',
              backgroundColor: '#00a859',
              color: '#fff',
              fontWeight: 'bold',
              border: 'none',
              fontFamily: 'Poppins',
              height: '40px',
              marginTop: '40px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              right: 20,
            }}
            onClick={() => handleReportGenerate()}
          >
            Gerar Relatório
          </button>
        </div>
      ) : !loading && saldos.length === 0 ? (
        <h4 style={{ color: '#333' }}>
          Não foram encontrados saldos em aberto
        </h4>
      ) : null}
      {/* Table */}
      <TableContainer>
        <table id="tableDataPesagens">
          <thead style={{ visibility: 'hidden' }}>
            <tr>
              <th>Ordem</th>
              <th>Data</th>
              <th>Bus.Partner</th>
              <th>Client</th>
              <th>Tipo</th>
              <th>Produto</th>
              <th>Unid.</th>
              <th>Volume</th>
              <th>Faturado</th>
              <th>Saldo SAP</th>
              <th>Agendado</th>
              <th>Saldo Final</th>
            </tr>
          </thead>

          {saldos.length > 0 ? (
            <thead>
              <th>Ordem</th>
              <th>Data</th>
              <th>Bus.Partner</th>
              <th>Client</th>
              <th>Tipo</th>
              <th>Produto</th>
              <th>Unid.</th>
              <th>Volume</th>
              <th>Faturado</th>
              <th>Saldo SAP</th>
              <th>Agendado</th>
              <th>Saldo Final</th>
            </thead>
          ) : null}

          {saldos.length > 0
            ? saldos
                .sort((a, b) => (b.pedido > a.pedido ? 1 : -1))
                // Mostrar apenas pedidos com saldos > 0
                // .filter(sld => sld.saldo > 0)
                .map(saldo => (
                  <tbody key={saldo.pedido}>
                    <tr>
                      <td className="sldPedido">{saldo.pedido}</td>
                      {/* <td className="sldData">
                        {formatDate(new Date(saldo.date))}
                      </td> */}
                      <td>{saldo.date}</td>
                      <td className="sldBPartner">
                        {saldo.cliente.businessPartner}
                      </td>
                      <td className="sldCliente">{saldo.cliente.name}</td>
                      <td className="sldTipo">{saldo.orderType}</td>
                      <td className="sldProduto">{saldo.material}</td>
                      <td className="sldUnidade">{saldo.unidadeControle}</td>
                      <td className="sldVolume">{saldo.quantidade}</td>
                      <td className="sldFaturado">
                        {saldo.quantidadeFaturada}
                      </td>
                      <td className="sldSaldo">{saldo.saldoOrder}</td>
                      <td className="sldAgendado">{saldo.volumeAgendado}</td>
                      <td className="sldSaldoFinal" style={ saldo.saldoCalculado < 0 ? {color: 'red'} : {}}>
                        {new Intl.NumberFormat('pt-BR').format(
                          saldo.saldoCalculado,
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))
            : null}
          {/* <tfoot>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th>{pesagens.length > 0 ? 'Total' : null}</th>
              <th>{pesagens.length > 0 ? handleReduceTotal() : null}</th>
            </tr>
          </tfoot> */}
        </table>
      </TableContainer>
    </Container>
  );
}

export default PainelSaldos;
