import React, { ReactElement } from 'react';

import GlobalStyle from './styles/global';
import { ErrorBoundary } from 'react-app-error-boundary';
import AppProvider from './hooks';
import { Route, Routes } from 'react-router-dom';
import InitialPage from './pages/InitialPage';
import Portal from './pages/Portal';
import SignIn from './pages/SignIn';
import PainelAdmin from './pages/PainelAdmin';
import AdminCli from './pages/AdminCli';
import JobsPage from './pages/JobsPage';
import AboutUs from './pages/AboutUs';
import FilaEtanol from './pages/FilaEtanol';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import CadastroVeiculos from './pages/CadastroVeiculos';
import AgendamentoPortal from './pages/AgendamentoPortal';
import PainelRh from './pages/PainelRh';
import PainelLogistica from './pages/PainelLogistica';
import PainelFaturamento from './pages/PainelFaturamento';
import PainelAgendamentos from './pages/PainelAgendamentos';
import PainelCarregamentos from './pages/PainelCarregamentos';
import ManutencaoMotoristas from './pages/ManutencaoMotoristas';
import ManutencaoVeiculos from './pages/ManutencaoVeiculos';
import ProtectedRoute from './routes/ProtectedRoute';
import FilaOutrosProdutos from './pages/FilaOutrosProdutos';
import PainelOverviewCarregamentos from './pages/PainelOverviewCarregamentos';
import PainelLaboratorio from './pages/PainelLaboratorio';
import FilaMonitorCarregamento from './pages/FilaMonitorCarregamento';
import PainelSaldos from './pages/PainelSaldos';

function App(): ReactElement {
  return (
    <AppProvider>
      <ErrorBoundary>
        <Routes>
          {/* Public */}
          <Route index path="/" element={<InitialPage />} />
          <Route path="/jobs" element={<JobsPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/fila" element={<FilaEtanol />} />
          <Route path="/filaoutros" element={<FilaOutrosProdutos />} />

          <Route
            path="/monitorcarregamento-etanol"
            element={<FilaMonitorCarregamento />}
          />
          <Route
            path="/monitorcarregamento-vhp"
            element={<FilaMonitorCarregamento />}
          />
          <Route
            path="/monitorcarregamento-bagaco"
            element={<FilaMonitorCarregamento />}
          />

          {/* <Route path="/filabagaco" element={<FilaBagaco />} /> */}
          {/* <Route path="/filavhp" element={<FilaVhp />} /> */}
          <Route path="/login" element={<SignIn />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />

          {/* Portal */}
          <Route
            element={
              <ProtectedRoute requirement="transportadora" redirectPath="/" />
            }
          >
            <Route path="/portal" element={<Portal />} />
            <Route path="/cadastro-veiculos" element={<CadastroVeiculos />} />
            <Route path="/agendamento" element={<AgendamentoPortal />} />
          </Route>

          {/* Logistica */}
          <Route
            element={
              <ProtectedRoute requirement="logistica" redirectPath="/" />
            }
          >
            <Route path="/painellogistica" element={<PainelLogistica />} />
            <Route
              path="/painelagendamentos"
              element={<PainelAgendamentos />}
            />
            <Route
              path="/painelcarregamentos"
              element={<PainelCarregamentos />}
            />

            <Route
              path="/carregamentosoverview"
              element={<PainelOverviewCarregamentos />}
            />

            <Route path="/saldoordenssap" element={<PainelSaldos />} />
          </Route>

          {/* Admin */}
          <Route
            element={<ProtectedRoute requirement="admin" redirectPath="/" />}
          >
            <Route path="/paineladmin" element={<PainelAdmin />} />
            <Route path="/admin" element={<AdminCli />} />
            <Route path="/motoristas" element={<ManutencaoMotoristas />} />
            <Route path="/veiculos" element={<ManutencaoVeiculos />} />
          </Route>

          {/* RH */}
          <Route element={<ProtectedRoute requirement="rh" redirectPath="/" />}>
            <Route path="/painelrh" element={<PainelRh />} />
          </Route>

          {/* Faturamento */}
          <Route
            element={
              <ProtectedRoute requirement="faturamento" redirectPath="/" />
            }
          >
            <Route path="/painelfaturamento" element={<PainelFaturamento />} />
          </Route>

          {/* Laboratorio */}
          <Route
            element={
              <ProtectedRoute requirement="laboratorio" redirectPath="/" />
            }
          >
            <Route path="/painellaboratorio" element={<PainelLaboratorio />} />
          </Route>
        </Routes>

        <GlobalStyle />
      </ErrorBoundary>
    </AppProvider>
  );
}

export default App;
