/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import type {} from '@mui/lab/themeAugmentation';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, darken } from '@mui/material';

export type ILoadingButtonProps = LoadingButtonProps & {
  text: string;
  icon: ReactElement;
  loading: boolean;
  customStyles?: any;
};

export default function CustomLoadingButton({
  text,
  icon,
  loading,
  customStyles,
  ...loadingButtonProps
}: ILoadingButtonProps) {
  const theme = createTheme({
    components: {
      MuiTimeline: {
        styleOverrides: {
          root: {
            backgroundColor: 'red',
          },
        },
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ '& > button': { m: 1 } }}>
          <LoadingButton
            {...loadingButtonProps}
            endIcon={icon}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            sx={{
              ...customStyles,
              background: '#00a859',
              height: '56px',
              padding: '1rem',
              width: '160px',
              ':hover': {
                background: darken('#00a859', 0.3),
              },
            }}
          >
            <span>{text}</span>
          </LoadingButton>
        </Box>
      </ThemeProvider>
    </div>
  );
}
